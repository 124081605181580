import React, {useEffect} from 'react'
import {navigate} from 'gatsby-link';
import PageWrapper from '../components/PageWrapper';

const IndexPage = () => {
    useEffect(() => {
        navigate('/propertime-efficient-time-reporting');
    }, []);

    return <>
        <PageWrapper themeConfig={{
            footerStyle: 'none',
            footerClassName: 'pt-13 pt-lg-25 pb-13 pb-lg-10',
        }}>
        </PageWrapper>
    </>;
}

export default IndexPage
